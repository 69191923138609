.list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.listItem {
  margin-left: 1.5rem;
  font-size: 1.25rem;
}

.nav a {
  text-decoration: none;
  color: #88dfdf;
}

.nav a:hover,
.nav a:active,
.nav a.active {
  color: #e6fcfc;
}

.navimage {
  border-radius: 10em;
  border-width: 10em;
}

.navitem{
  color:white;
  text-decoration: none;
}

.navitemactive{
  color:darkgray;
  text-decoration:underline;
}

.navitem:hover{
  color:darkgray;
  text-decoration: underline;
}


