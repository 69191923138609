.main {
  margin: 3rem auto;
  width: 90%;
  max-width: 40rem;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.container{
  max-width: 100%;
}