.list {
  display: inline;
}

.card {
  background: rgb(223, 113, 0);
  background: linear-gradient(
    90deg,
    rgba(223, 113, 0, 0.98) 71%,
    rgba(246, 196, 107, 0.98) 94%,
    rgba(70, 72, 79, 0.98) 100%
  );
  height: 100%;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

h1 {
  text-align: center;
}

.cardbody {
  padding-right: 0;
  padding-left: 0;
}
